import React , { useState } from 'react';
import '../styles.scss'; 
import ProjectItem from '../project_item_v1';

function Project(props) {
        const {data,title} = props;
        const [maxH, setMaxH] = useState(0);

        const getMaxH = (h)=>{
            if (h > maxH){
                setMaxH(h);
            }
        }

    return (
        <section className="tf-section project_2">
        <div className="container w_1690">
            <div className="row">
                <div className="col-md-12">
                    <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                        <h2 className="title">
                            {title} <br className="show-destop" />
                        </h2>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="container_inner">
                    <div className="flex-row d-flex flex-wrap justify-content-between">
                                {
                                    data.map(item => (
                                        <div key={item.id} className={'col-12 col-lg-6 col-xl-3 p-2'}>
                                            <ProjectItem contentHeight={maxH > 0 ? maxH : undefined} onLoad={getMaxH} item={item} />
                                        </div>
                                    ))
                                }
                            </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </section>
    );
}

export default Project;
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Pagination, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import '../styles.scss';
import {isMobile} from "../../../utils";

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data,title,sectionId} = props;
    const [selected, setSelected] = useState(0);


    return (
        <section className="tf-section project pt-5" id={sectionId}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb-5" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                {title || "Projects"}<br className="show-destop"/>
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="project-wrapper">
                            <div className="image-wrapper" data-aos="fade-in" data-aos-duration="1000">
                                <div className="slider-1">
                                    <Swiper
                                        modules={[Pagination, A11y]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        onSlideChange={(e) => setSelected(e.activeIndex)}
                                        pagination={{clickable: true}}
                                        navigation={isMobile()}
                                    >
                                        {
                                            data.map(item => (
                                                <SwiperSlide key={item.id} item={item}>
                                                    <img loading={'lazy'} src={item.img} alt="MoleSoul"/>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            <div className="content-wrapper" style={{minWidth: 450}}>
                                <div className="content_inner" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="wrapper">
                                        <h4>{data[selected].title}</h4>
                                        <p className="desc">
                                            {data[selected].desc}
                                        </p>
                                        <p className={'price'}></p>
                                        {data[selected].url && <p>
                                            <a target={'_blank'} rel={'noreferrer'} href={data[selected].url}>
                                                {data[selected].url.replace('https://', '')}
                                            </a>
                                        </p>}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
        ;
}

export default Project;

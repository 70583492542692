import React from 'react';
import '../styles.scss'
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import {isMobile} from "../../../utils";

Banner.propTypes = {
    data : PropTypes.array,
};

function Banner (props) {
    const {data} = props;

    const showImg = !isMobile();
    return (
        <div className="page-title">
            <div className="icon_bg">
                {showImg &&<img loading={'lazy'} src={require ('../../../assets/images/backgroup/bg_inner_slider.webp')} alt="background" />}
            </div>
            <div className="slider-main">
            <Swiper
                modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                >
                {
                    data.slice(0,2).map(item => (
                        <SwiperSlide key={item.id}>
                            <div className="slider-st1">
                                <div className="overlay" id={'bgLazyLoad'}>
                                    {showImg && <img loading={'lazy'} src={require ('../../../assets/images/backgroup/bg-slider.webp')} alt="MoleSoul" />}
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box-slider">
                                                <div className="content-box">

                                                    <h1 className="title" >{item.heading}</h1>
                                                    <p className="sub-title">{item.sub_heading}</p>
                                                </div>
                                                <div className="image">
                                                    <img className="img_main" src={item.img1} alt="MoleSoul" />
                                                    {item.img2 && <div className="icon icon1">
                                                        <img  src={item.img2} alt="MoleSoul" />
                                                    </div>}
                                                    {item.img3 && <div className="icon icon2">
                                                        <img  src={item.img3} alt="MoleSoul" />
                                                    </div>}
                                                    {item.img4 && <div className="icon icon3">
                                                        <img  src={item.img4} alt="MoleSoul" />
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            </div>
        </div>
    );
}

export default Banner;

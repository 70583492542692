import React from 'react';
import '../scss/component/_section.scss';
import '../scss/component/_box.scss';
import '../scss/component/_reponsive.scss';
import Banner from '../components/banner/banner_v1';
import Project from '../components/project/project_v1';
import Project2 from '../components/project/project_v2';

import {data, dataPrivateProject, dataBanner, dataServices} from '../assets/data';
import Button from "../components/button";

function HomeOne(props) {

    return (
        <div className='header-fixed main home1 counter-scroll'>

            {<Banner data={dataBanner}/>}
            {<Project2 data={dataServices} title={"Naše služby"}/>}
            {<section className="tf-section project_2 pt-5">
                <div className="container w_1690">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <Button title='Kontaktujte nás'
                                    path={'/contact'}
                                    addclass='style2'/>
                        </div>
                    </div>
                </div>
            </section>}
            {<Project sectionId={'portfolio'} data={data} title={"Web vizitka"}/>}
            {<Project sectionId={'portfolioApp'} data={dataPrivateProject} title={"Web aplikácie"}/>}


        </div>
    );
}

export default HomeOne;

import React, {useState, useEffect} from 'react';

import {NavLink} from 'react-router-dom';
import './styles.scss';
import logo from '../../assets/images/msLogoW.webp'
import {isMobile} from "../../utils";


const Header = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const showImg = !isMobile();

console.log('show',showImg)
    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}>
                        <span></span></div>
                    <div className="header__logo ps-4">
                        {showImg &&<NavLink to="/"><img src={logo} style={{height: 90}} alt="MoleSoul"/></NavLink>}
                    </div>
                </div>
            </div>
        </header>


    );
}

export default Header;

import React, {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';

function Banner(props) {
    const {item, onLoad, contentHeight} = props;
    const contentRef = useRef(null);

    useEffect(()=>{
        onLoad(contentRef.current.clientHeight)
    },[onLoad])

    return (
        <div className="project-box">
            <div style={{height: '6rem', position: 'relative', zIndex: 20, textAlign: 'center'}}>
                <img loading={'lazy'} style={{
                    background: 'linear-gradient(180deg, #1A222C 17.33%, rgba(35, 44, 54, 0) 100%)',
                    borderRadius: '25%',
                    padding: '15px'
                }} src={item.img} alt="MoleSoul"/>
            </div>
            <div className="content" ref={contentRef}
                 style={{height: contentHeight > 0 ? `${contentHeight}px` : undefined}}>
                <span className="boder"></span>
                <div className="content-inner">
                    <h5 className="heading pb-2"><Link to="/project_list">{item.title}</Link></h5>
                    <p className="desc">{item.desc}</p>
                </div>
            </div>
        </div>
    );
}

export default Banner;

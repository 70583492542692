import React, {startTransition} from 'react';

function Contact(props) {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const sendMail = () => {
        const isEmail = (email) => {
            const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return validRegex.test(email)
        }
        if (name.length === 0 || email.length === 0 || message.length === 0){
            return alert('Vyplňte všetky povinné polia (Meno, Email, Správa)')
        }
        if (!isEmail(email)) return alert('Email v nesprávnom formáte')
        fetch(`/contact.php?name=${name}&email=${email}&message=${message}&phone=${phone}`, {
            mode: 'no-cors',
            method: 'GET'
        }).then(res => {
            alert('Vďaka že ste nás kontaktovali')
            window.gtag('event', 'conversion', {
                    'send_to': 'AW-606966372/0V1eCNr7yckZEOSktqEC',
                });
            startTransition(()=>{
                setMessage('');
                setPhone('');
                setEmail('');
                setName('');
            })
        }).catch((err) =>{
                alert('Chyba. Skúste neskôr.')
                console.log(err)
        });
    }
    return (
        <div className='inner-page'>
            <section className="tf-section tf-contact pt60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-title mb40" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    Kontakt
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <ul className="contact-info">
                                <li>
                                    <div className="icon">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M27.1875 8.4375V21.5625C27.1875 22.3084 26.8912 23.0238 26.3637 23.5512C25.8363 24.0787 25.1209 24.375 24.375 24.375H5.625C4.87908 24.375 4.16371 24.0787 3.63626 23.5512C3.10882 23.0238 2.8125 22.3084 2.8125 21.5625V8.4375M27.1875 8.4375C27.1875 7.69158 26.8912 6.97621 26.3637 6.44876C25.8363 5.92132 25.1209 5.625 24.375 5.625H5.625C4.87908 5.625 4.16371 5.92132 3.63626 6.44876C3.10882 6.97621 2.8125 7.69158 2.8125 8.4375M27.1875 8.4375V8.74125C27.1876 9.22143 27.0647 9.69363 26.8305 10.1129C26.5964 10.5321 26.2588 10.8844 25.85 11.1363L16.475 16.905C16.0315 17.1782 15.5209 17.3228 15 17.3228C14.4791 17.3228 13.9685 17.1782 13.525 16.905L4.15 11.1375C3.74115 10.8857 3.40359 10.5334 3.16946 10.1141C2.93534 9.69488 2.81245 9.22268 2.8125 8.7425V8.4375"
                                                stroke="#798DA3" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>

                                    </div>
                                    <div className="content">
                                        <p>Email</p>
                                        <p className="color-hover">molesoul@molesoul.sk</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section project-info pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="project-info-form">
                                <h6 className="title">Zanechajte nám správu</h6>
                                <div className="form-inner">
                                    <fieldset>
                                        <label>
                                            Meno
                                        </label>
                                        <input value={name} onChange={(e) => setName(e.target.value)} type="text"
                                               id="name" placeholder="Meno" required/>
                                    </fieldset>
                                    <fieldset>
                                        <label>
                                            Email
                                        </label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email"
                                               id="email" placeholder="Email" required/>
                                    </fieldset>
                                    <fieldset>
                                        <label>
                                            Telefónne číslo (nepovinné)
                                        </label>
                                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="number"
                                               id="phone" placeholder="0900 123456"/>
                                    </fieldset>
                                    <fieldset>
                                        <label htmlFor="message">
                                            Správa
                                        </label>
                                        <textarea value={message} onChange={(e) => setMessage(e.target.value)}
                                                  id="message"
                                                  placeholder="Napísťe nám v skratke o akú službu máte záujem"
                                                  rows="5" tabIndex="4" name="message" className="message"
                                                  required></textarea>
                                    </fieldset>
                                </div>
                                <div className="wrap-btn pt-4">
                                    <button onClick={() => {
                                        sendMail()
                                    }} className="tf-button style1">
                                        Odoslať správu
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Contact;
import img1 from './images/slider/img_slider_1.webp';
import img2 from './images/slider/img_slider_2.webp';
import img3 from './images/slider/img_slider_3.webp';
import img4 from './images/slider/img_slider_5.webp';
import img5 from './images/slider/img_slider_4.webp';
import img6 from './images/slider/img_slider_6.webp';
import imgHero from './images/slider/Furore.webp';
import icon1 from './images/icon-app.webp'
import icon2 from './images/icon-web.webp'
import icon3 from './images/icon-seo.webp'
import icon4 from './images/icon-process.webp'

const dataServices = [
    {
        id: 1,
        title: 'Mobilné aplikácie',
        desc: "Chcete mať vlastnú mobilnú aplikáciu? My ju pre vás vytvoríme! Od nápadu po zverejnenie v obchode s aplikáciami. Profesionálny vývoj aplikácií pre iOS a Android.",
        img:icon1
    },
    {
        id: 2,
        title: 'Web stránky / aplikácie',
        desc: "Hľadáte spôsob, ako posunúť vaše podnikanie online? Zveríme sa do rúk profesionálov. Vyvíjame custom web aplikácie a stránky, ktoré sú nielen vizuálne príťažlivé, ale aj užívateľsky prívetivé a optimalizované pre vyhľadávače.",
        img:icon2
    },
    {
        id: 3,
        title: 'SEO Optimalizácia',
        desc: "Zlepšite svoju pozíciu na Google a získajte konkurenčnú výhodu. Naša SEO optimalizácia zabezpečí, že vaša stránka bude ľahko nájdená potenciálnymi zákazníkmi. Investujte do budúcnosti vášho podnikania.",
        img:icon3
    },
    {
        id: 4,
        title: 'Automatizácia procesov',
        desc: "Zvýšte produktivitu vašej firmy vďaka automatizácii procesov. Naše riešenie prispôsobíme vašim špecifickým potrebám a zabezpečíme hladký chod vašich operácií.",
        img:icon4
    }
]
const data = [
    {
        id: 1,
        img: img6,
        title: "Centrum suchého oka",
        desc: "Vizitka spoločnosti",
        items: [""],
        technologies: [],
        url: "https://www.sucheoko.sk"
    },
    {
        id: 2,
        img: img3,
        title: "Veselsky s.r.o.",
        desc: "Vizitka spoločnosti",
        items: [""],
        technologies: [],
        url: "https://www.veselsky.sk"
    },
    {
        id: 3,
        img: img2,
        title: "Triomont Pro",
        desc: "Vizitka spoločnosti",
        items: [],
        technologies: [],
        url: "https://www.triomontpro.sk"
    },

]

const dataPrivateProject = [
    {
        id: 1,
        img: img4,
        title: "Inventúra obchodov",
        desc: "WooCommerce, Notifikácie, Analytics",
        items: [""],
        technologies: [],
        url: ""
    },
    {
        id: 2,
        img: img1,
        title: "Slovenská oftalmologická spoločnosť",
        desc: "Stránka s privátnou zónou pre členov.",
        items: ["správa členov, kalendára, dokumentov", "vytváranie galérii fotiek", "posielanie newsletterov"],
        technologies: [],
        url: "https://www.soska.sk"
    },
    {
        id: 3,
        img: img5,
        title: "Manažment firmy",
        desc: "Fakturačný / Účtovny program",
        items: ["faktúry","účtovníctvo",],
        technologies: [],
        // url: "https://www.soska.sk"
    }

]

const dataBanner = [{
    id: 1,
    heading: 'Zrýchlite vaše procesy raketovou rýchlosťou',
    sub_heading: 'Tvoríme aplikácie a automatizované procesy ktoré Vám pomáhaju sústrediť sa na podstatné činnosti',
    img1: imgHero,
},]
export {data, dataPrivateProject, dataBanner, dataServices};
